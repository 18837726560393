<template>
	<div>
		<div class="js-copy js-content pd_body">
			<headerNav :currentPath="'CryptoFiPartner'"> </headerNav>

			<div class="f_232_prople f_232_prople_1 f_item">
				<div class="f_item_header_4">
					<div class="f_item_header_3"> 成为赞助商</div>
					<div class="f_item_header_2"> </div>
				</div>
				<div class="f_232_prople_1_text">
					我们诚邀您加入“香港加密金融论坛
					2025”，携手推动加密金融行业的创新与发展。<br />
					如需成为赞助商，请发送邮件至 event@metaera.hk
				</div>
				<div class="f_232_prople_1_email" @click="sendEmail('event@metaera.hk')"
					>发送邮件</div
				>
			</div>

			<div class="f_partners f_partners_1 f_item">
				<div class="f_item_header_4">
					<div class="f_item_header_3_t"> 合作伙伴</div>
					<div class="f_item_header_2"> </div>
				</div>

				<div class="f_item_header_4">
					<div class="f_item_header_3_1"> 主办</div>
					<div class="f_item_header_2_1"> </div>
				</div>
				<div class="f_partners_body">
					<img
						v-for="(item, index) in partners3"
						:key="index"
						class="f_partners_body_img pointer"
						:src="item.pic"
						@click="showUrl(item.link)"
					/>
				</div>
				<div class="f_item_header_4">
					<div class="f_item_header_3_1"> 联合主办</div>
					<div class="f_item_header_2_1"> </div>
				</div>
				<div class="f_partners_body">
					<img
						v-for="(item, index) in partners2"
						:key="index"
						class="f_partners_body_img pointer"
						:src="item.pic"
						@click="showUrl(item.link)"
					/>
				</div>

				<div class="f_item_header_4">
					<div class="f_item_header_3_1"> 协办</div>
					<div class="f_item_header_2_1"> </div>
				</div>
				<div class="f_partners_body">
					<img
						v-for="(item, index) in partners"
						:key="index"
						class="f_partners_body_img pointer"
						:src="item.pic"
						@click="showUrl(item.link)"
					/>
				</div>
				<div class="f_item_header_4">
					<div class="f_item_header_3_1"> 赞助商</div>
					<div class="f_item_header_2_1"> </div>
				</div>

				<div class="f_item_header_3_1_1">白金赞助</div>
				<div class="f_partners_body">
					<img
						v-for="(item, index) in payPartners"
						:key="index"
						class="f_partners_body_img pointer"
						:src="item.pic"
						@click="showUrl(item.link)"
					/>
				</div>
				<div class="f_item_header_3_1_1">高级赞助</div>

				<div class="f_partners_body">
					<img
						v-for="(item, index) in payPartners1"
						:key="index"
						class="f_partners_body_img pointer"
						:src="item.pic"
						@click="showUrl(item.link)"
					/>
				</div>

				<div class="f_item_header_3_1_1">媒体合作伙伴</div>

				<div class="f_partners_body">
					<img
						v-for="(item, index) in partners4"
						:key="index"
						class="f_partners_body_img pointer"
						:src="item.pic"
						@click="showUrl(item.link)"
					/>
				</div>
				<div class="f_next" @click="next(6)">
					<img
						src="https://res.metaera.hk/resources/assets/images/forum/2.png"
						alt=""
					/>
				</div>
			</div>
			<bottom></bottom>
		</div>
	</div>
</template>

<script>
import headerNav from "./headern.vue";
import bottom from "./pdbottom.vue";

import Vue from "vue";
export const newBus = new Vue();
export default {
	name: "",
	components: { headerNav, bottom },
	data() {
		return {
			osafai: false,
			activeTab: 1,
			partners: [
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/3.0协会@1x.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/金融协会@1x.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/img_v3_02kf_4f63a2a5-c9ee-488b-a5fe-d3c9f77f4fhu.webp",
					link: "",
					height: 60,
				},
			],
			partners2: [
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/20250318-212904.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/20250310-203843.png",
					link: "",
					height: 60,
				},
			],
			partners3: [
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/CRYPTOFI ME LOGO.png",
					link: "",
					height: 60,
				},
			],

			partners4: [
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/m1.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/m2.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/m3.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/m4.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/m5.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/m6.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/m7.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/blocktempo.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/techub._LOGO_1.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/m8.png",
					link: "",
					height: 60,
				},
			],
			payPartners: [
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/fenbushi_black.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/原-横.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/image (2).png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/20250401-225044.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/20250310-213727.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/20250319-174819.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/ChinaAMC_logo_TC.png",
					link: "",
					height: 60,
				},
			],
			payPartners1: [
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/AlloyX.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/20250310-213731.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/WSPN logo_black.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/fomopay.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/纯英文.jpg",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/CHAIN-常规.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/RD Technologies - Brand Logo - RGB - Dark (Light BG).png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/Klickl_Black.svg",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/img_v3_02kf_8f0fc870-71e4-4473-bc14-3ebde04343hu.jpg",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/WechatIMG313.jpg",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/20250312-165932.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/alsa.jpg",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/雨川.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/pse.jpg",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/Black icon Black text.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/sunpump logo.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/pythia (2).png",
					link: "",
					height: 60,
				},
			],
		};
	},
	created() {},
	mounted() {
		this.selHKLanguage();
	},
	methods: {
		sendEmail(url) {
			const email = url;
			const subject = "";
			const body = "";
			window.location.href = `mailto:${email}?subject=${encodeURIComponent(
				subject
			)}&body=${encodeURIComponent(body)}`;
		},
		selHKLanguage() {
			const index = 0;
			this.showSel = 0;
			this.localLanguage = index;
			sessionStorage.setItem("localLanguageHK", "简体中文");
			if (this.localLanguage == 0) {
				this.$t2s(document.body);
			} else {
				this.$e2s(document.body, 0);
			}
			// 通知发送
			newBus.$emit("global-notification", {
				localLanguage: this.localLanguage,
			});
		},
		next(num) {},
		top() {
			this.next(-1);
		},
	},
};
</script>

<!-- f_indicator -->
<style scoped>
.f_indicator_sub {
	width: 51px;
	height: 40px;
	opacity: 1;
	position: absolute;
	bottom: 10px;
	right: 10%;
	z-index: 999;
	display: block;
	align-content: center;
	align-items: center;
	text-align: center;
	display: none;
}
.f_indicator_sub_num {
	margin-left: 17px;
	font-size: 20px;
	font-weight: 400;
	line-height: 20px;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;
	color: #ffffff;
	height: 20px;
	align-content: center;
	align-items: center;
	text-align: center;
}
.f_indicator_sub_line {
	margin-top: 10px;
	margin-left: 17px;
	width: 17px;
	height: 2px;
	border-radius: 43px;
	opacity: 1;
	/* 纯白 */
	background: #ffffff;
}
.f_indicator_sub_img {
	margin-top: 20px;
	width: 51px;
	height: auto;
}
.f_indicator {
	text-align: center;
	align-content: center;
	width: 80px;
	height: 180px;
	opacity: 1;
	z-index: 999;
	position: fixed;
	top: calc(50vh - 110px);
	right: 20px;
	z-index: 999;
	/* background: orange; */
	display: flex;
	display: none;
}

.f_indicator_left {
	width: 2px;
	height: 100%;
	display: flex;
}

.f_indicator_line {
	width: 2px;
	height: 100%;
	opacity: 0.5;
	/* 纯白 */
	background: #ffffff;
}
.f_indicator_right {
	margin-top: 20px;
	width: 50px;
}

.f_indicator_item {
	height: 30px;
	width: 100%;
	display: flex;

	position: relative; /* 父容器需要相对定位，作为子元素的定位参考 */
}
.f_indicator_triangle {
	position: relative; /* 子元素1相对自己的默认位置 */
	width: 8px;
	height: 8px;
	color: white;
	display: flex;
	justify-content: center;
	top: 0px; /* 相对自身位置向下移动 */
	left: 0px; /* 相对自身位置向右移动 */
}
.f_indicator_triangle img {
	width: 100%;
	height: auto;
	object-fit: cover;
}
.f_indicator_pointer {
	opacity: 0.5;
	background: #e0eaff;
	width: 6px;
	height: 6px;
	border-radius: 3px;

	position: absolute; /* 子元素1相对自己的默认位置 */
	display: flex;
	justify-content: center;
	top: 0px; /* 相对自身位置向下移动 */
	right: 0px; /* 相对自身位置向右移动 */
}

.f_indicator_pointer_sel {
	width: 20px;
	opacity: 1;
	background: #e0eaff;
}
@media screen and (max-width: 900px) {
	.f_indicator,
	.f_indicator_sub {
		display: none;
	}
}
</style>
<style scoped>
.pd_body {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	/* height: 100vh; */
	overflow-y: auto; /* 可滚动 */
	scroll-behavior: smooth; /* 平滑滚动 */
}
.scroll-container {
	height: 100vh;
	overflow-y: scroll;
}
.js-copy {
	position: absolute;
	top: 0px;
	left: 0;
	width: calc(100vw);
	margin: 0 auto;
	background: #3d29aa;
}
</style>
<style scoped>
.f_next {
	position: absolute; /* 子元素设置为绝对定位 */
	bottom: 40px; /* 距离底部为 0 */
	width: 100%; /* 可选：设置宽度为父容器宽度 */
	text-align: center;
	display: block;
	display: none;
}
.f_next img {
	width: 32px !important;
}
.f_item {
	position: relative;
	width: 100%;
	/* min-height: calc(100vh - 80px); */
	height: auto;
	background-image: url("https://res.metaera.hk/resources/assets/images/forum/5000.png");
	background-size: cover; /* 背景图片缩放适配 */
	background-position: center; /* 背景图片居中 */
	background-repeat: no-repeat; /* 不重复背景图片 */
	text-align: center;
	padding-top: 0px;
}
@media screen and (max-width: 900px) {
	.f_item {
		min-height: auto;
		height: auto;
		padding-top: 10px;
	}
	.f_next {
		bottom: 70px; /* 距离底部为 0 */
		display: none;
	}
}
</style>
<!-- f_value -->
<style scoped>
.f_value {
	text-align: start;
	background: #7a64ff;
}

.f_value_top {
	margin-top: -80px;
	padding-top: 28px;
	margin-left: 5%;
	margin-right: 5%;
	border-radius: 20px;
	text-align: center;
	align-content: center;
	align-items: center;
	opacity: 1;
	background: #6429ff;
}
.f_item_header {
	text-align: center;
	align-content: center;
	align-items: center;
	display: inline-block;
	border-radius: 5px;
	width: 120px;
	opacity: 1;
	background: #ffffff;
}

.f_item_header_1 {
	text-align: center;
	align-content: center;
	align-items: center;
	font-size: 24px;
	height: 40px;
	font-weight: bold;
	line-height: normal;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #7a64ff;
}

.f_item_header_2 {
	/* 纯白 */
	background: #8bffae;
	height: 4px;
	width: 27px;
	text-align: center;
	align-content: center;
	display: inline-block;
}

.f_item_header_2_1 {
	background: #6429ff;
	margin-top: -40px;
	height: 20px;
	width: 60px;
	text-align: center;
	align-content: center;
	display: inline-block;
	border-radius: 10px;
}

.f_item_header_3,
.f_item_header_3_t {
	font-size: 44px;
	font-weight: bold;
	line-height: normal;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}

.f_item_header_3_1 {
	font-size: 34px;
	font-weight: bold;
	line-height: normal;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}

.f_item_header_4 {
	margin-top: 60px;
	text-align: center;
	align-content: center;
	align-items: center;
	opacity: 1;
}
.f_item_body {
	margin-top: 20px;
	margin-left: 10%;
	margin-right: 10%;
	display: flex;
}
.f_item_body1 {
	margin-top: 2%;
}
.f_item_body2 {
	margin-top: 2%;
}

.f_item_body_block {
	position: absolute;
	width: 70px;
	height: 120px;
	opacity: 1;
	z-index: 1;
	background: #6429ff;
}

@media screen and (max-width: 900px) {
	.f_value {
		min-height: auto;
	}
	.f_item_body_block {
		width: 30px;
	}
	.f_item_body_block3 {
		height: 300px !important;
	}
	.f_item_header {
		margin-top: 20px;
	}
	.f_item_header_3_t {
		font-size: 20px;
	}
	.f_item_header_3 {
		font-size: 24px;
	}
	.f_item_header_3_1 {
		font-size: 16px;
	}

	.f_item_header_4 {
		margin-top: 30px;
	}
}
.f_item_body_block3 {
	height: 210px;
}

.f_item_body_block4 {
	height: 120px;
	width: 40px;
}

.f_item_body_block5 {
	height: 160px;
}

.f_item_body_block6 {
	height: 200px;
	position: absolute;
}
.f_item_body_block7 {
	width: 40px;
	height: 140px;
}

.f_item_body_block8 {
	width: 40px;
	height: 60px;
}
.f_item_body_text {
	padding-top: 30px;
	padding-bottom: 20px;
	margin-left: 40px;
	font-size: 24px;
	font-weight: 400;
	line-height: normal;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}
@media screen and (max-width: 900px) {
	.f_item_body_text {
		font-size: 20px;
		margin-left: 20px;
		margin-right: 20px;
	}
	.f_item_body_text3,
	.f_item_body_text2,
	.f_item_body_text1 {
		font-size: 15px !important;
		margin-left: 20px !important;
		margin-right: 20px !important;
	}
	.f_item_body_block6 {
		height: 400px;
	}
}
.f_item_body_text1 {
	font-size: 20px;
	margin-left: 20px;
}

.f_item_body_text2 {
	margin-left: 20px;
	font-size: 20px;
}

.f_item_body_text3 {
	font-size: 26px;
	font-weight: 400;
	line-height: 44px;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
	margin-left: 10%;
	margin-right: 10%;
}

@media screen and (max-width: 900px) {
	.f_item_header_1 {
		font-size: 16px;
	}
	.f_item_header_2 {
		font-size: 20px;
	}
}
</style>
<!-- f_themes -->
<style scoped>
.f_themes {
	text-align: start;
	padding-top: 100px;
	/* min-height: calc(100vh - 80px); */
}
.f_themes_body {
	margin-top: 2%;
	margin-left: 10%;
	margin-right: 10%;
	display: flex;
	justify-content: space-between;
}

.f_themes_body_left,
.f_themes_body_right {
	width: 48%;
}

.f_themes_body_right_1 {
	display: grid;
	justify-content: space-between;
}

.f_themes_body_item {
	border-radius: 2px;
	opacity: 1;
	box-sizing: border-box;
	border: 2px solid #6454bb;
	padding: 20px;
	text-align: start;
	margin-bottom: 24px;
	min-height: 290px;
}
.f_themes_body_item_1 {
	min-height: auto;
}

.f_themes_body_item_line {
	opacity: 1;
	background: #8bffae;
	height: 4px;
	width: 40px;
}

.f_themes_body_item_name {
	margin-top: 10px;
	display: block;
	height: 60px;
}

.f_themes_body_item_name1 {
	margin-left: 10%;
	margin-top: 3%;
}

.f_themes_body_item_name_1 {
	margin-right: 10px;
	height: 30px;
}

.f_themes_body_item_name_1 span {
	padding-left: 6px;
	padding-right: 6px;
	font-size: clamp(16px, calc(100vw * 16 / 1280), 20px);
	font-weight: bold;
	line-height: normal;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
	opacity: 1;
	background: #6429ff;
}

.f_themes_body_item_name_11 {
	min-width: 140px;
}
.f_themes_body_item_name_12 {
	min-width: 190px;
}
.f_themes_body_item_name_2 {
	font-size: clamp(8px, calc(100vw * 16 / 1280), 20px);
	font-weight: 400;
	line-height: normal;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}

.f_themes_body_item_title {
	margin-top: 20px;
	font-size: clamp(12px, calc(100vw * 14 / 1280), 20px);
	font-weight: 400;
	line-height: 30px;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
	min-height: clamp(8px, calc(100vw * 100 / 1280), 120px);
}

.f_themes_body_item_title_1 {
	min-height: 0px;
}

.f_themes_body_item_text_1 {
	min-height: 0px !important;
}
.f_themes_body_item_text {
	font-size: clamp(12px, calc(100vw * 16 / 1280), 18px);
	font-weight: 400;
	line-height: 1.5;
	letter-spacing: 0em;
	color: #ffffff;
	opacity: 0.6;
	min-height: clamp(60px, calc(100vw * 80 / 1280), 110px);
}
@media screen and (max-width: 900px) {
	.f_themes {
		margin-top: 40px;
		padding-top: 0px;
		min-height: auto;
	}

	.f_themes_body {
		display: block;
	}

	.f_themes_body_left,
	.f_themes_body_right {
		width: 100%;
	}
	.f_themes_body_item_name {
		margin-top: 10px;
		display: block;
		height: auto;
	}
	.f_themes_body_item_name_2 {
		font-size: 14px;
	}
	.f_themes_body_item_name_1 {
		height: auto;
	}
	.f_themes_body_item {
		min-height: 200px;
	}
}
</style>
<!-- f_home -->
<style scoped>
.f_home {
	align-content: flex-end;
	margin-top: 80px;
	background-image: url("https://res.metaera.hk/resources/assets/images/forum/64.png");
	display: flex;
	justify-content: space-between;
}

.f_home img {
	width: 100%;
	height: calc(100vh - 80px);
	object-fit: scale-down;
}

.f_home_1_img {
	width: 100%;
	height: auto !important;
	/* height: calc(100vh - 80px); */
	object-fit: contain;
}

.f_home_link {
	width: 120px;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 10px;
	padding-bottom: 10px;
	margin-top: 40px;
	display: flex;
	background: #000000;
	justify-content: space-between;
	border-radius: 6px;
}

.f_home_link img {
	width: 30px !important;
	height: 30px !important;
}

.f_home_5 {
	width: 30%;
	margin-right: 10%;
	/* margin-bottom: 25%; */
	/* margin-top: 20%; */
	z-index: 2;
	position: relative;
}

.f_home_1 {
	width: 30%;
	margin-left: 10%;
	/* margin-bottom: 25%; */
	/* margin-top: 20%; */
	z-index: 2;
	position: relative;
	align-items: center;
	align-content: center;
}
.f_home_2 {
	width: 100%;
	margin-bottom: 50px;

	font-size: 24px;
	font-weight: 400;
	line-height: 24px;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}
.f_home_3 {
	width: 16%;
	margin-left: 42%;
	margin-bottom: 120px;
}

@media screen and (max-width: 900px) {
	.f_home {
		margin-top: 50px;
		min-height: 80vh;
		background-image: url("https://res.metaera.hk/resources/assets/images/forum/50.png");
	}
	.f_home_5 {
		display: none;
	}
	.f_home_1 {
		width: 80%;
		margin-left: 10%;
		margin-bottom: 30%;
	}
	.f_home_2 {
		width: 100%;
		margin-bottom: 20px;
		font-size: 14px;
	}
	.f_home_3 {
		width: 40%;
		margin-left: 30%;
		margin-bottom: 60px;
	}
}
</style>
<!-- f_about -->
<style scoped>
.f_about {
	text-align: start;
	margin-bottom: 0px;
	padding-bottom: 100px;
	background: #7a64ff;

	/* min-height: calc(100vh - 280px); */
}
.f_about_1 {
	margin-top: 10px;
	font-size: 30px;
	font-weight: bold;
	line-height: 30px;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
	z-index: 2;
	position: relative;
	margin-left: 30px;
}
.f_about_1_1 {
	margin-left: 20px;
}
.f_about_2 {
	margin-top: 20px;
	margin-left: calc(50vw - 21px);
	width: 42px;
	height: 2px;
	border-radius: 323px;
	opacity: 1;
	background: #ffffff;
}
.f_about_3 {
	margin-top: 0px;
}

.f_about_3 img {
	width: 10px;
	height: auto;
}
.f_about_4 {
	margin-top: 30px;
	width: 100%;

	display: flex;
	align-items: center;
	text-align: start;
	text-transform: uppercase;
	letter-spacing: 0em;
	color: #ffffff;
	line-height: 1.5;
	font-size: 20px;
	font-weight: 400;

	position: relative;
	margin-left: 30px;
	z-index: 2;
}
.f_about_5 {
	margin-top: 50px;
	display: flex;
	height: 300px;
	padding-left: 5%;
	padding-right: 5%;
	justify-content: space-between;
}

.f_about_6,
.f_about_7 {
	display: none;
}

.f_about_5_item {
	width: 20%;
	height: 100%;
	display: flex; /* 使用 flexbox 布局 */
	flex-direction: column;

	justify-content: space-between;
	text-align: center;
	border-radius: 2px;
	opacity: 1;
	box-sizing: border-box;
}

.f_about_5_item1 {
	margin-top: 40px;
}

.f_about_5_item1 img {
	width: clamp(30px, calc(100vw * 60 / 1280), 80px);
	height: auto;
}

.f_about_5_item2 {
	width: 100%;
	font-size: clamp(20px, calc(100vw * 30 / 1280), 40px);
	font-weight: 900;
	line-height: 40px;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
}
.f_about_5_item3 {
	margin-bottom: 40px;

	font-size: clamp(16px, calc(100vw * 18 / 1280), 20px);
	font-weight: 500;
	line-height: 20px;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
}
@media screen and (max-width: 900px) {
	.f_about {
		margin-bottom: 50px;
		padding-top: 0px;
	}
	.f_about_1 {
		margin-left: 10px;
	}
	.f_about_4 {
		margin-top: 10px;
		margin-left: 5%;
		width: 90%;
		font-size: 16px;
	}
	.f_about_5 {
		display: none;
	}

	.f_about_1_2 {
		margin-left: 10px;
	}

	.f_about_6,
	.f_about_7 {
		display: flex;
		margin-top: 20px;
		display: flex;
		height: 40%;
		padding-left: 15%;
		padding-right: 15%;
		justify-content: space-between;
	}

	.f_about_5_item {
		width: 40%;
		background: rgba(255, 255, 255, 0);
		box-sizing: border-box;
		border: 0px solid #ffffff;
	}
	.f_about_5_item1 {
		margin-top: 20px;
	}

	.f_about_5_item1 img {
		width: clamp(40px, calc(100vw * 50 / 414), 60px);
		height: auto;
	}
}
</style>

<!-- f_partners -->
<style scoped>
.f_partners {
	text-align: start;
	background-image: url("https://res.metaera.hk/resources/assets/images/forum/64.png");
	padding-bottom: 70px;
}

.f_partners_1 {
	background: black;
}

.f_item_header_3_1_1 {
	font-size: 30px;
	font-weight: bold;
	line-height: normal;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
	align-content: center;
	align-items: center;
	text-align: center;
	margin-top: 30px;
}

.f_partners_body {
	margin-left: 10%;
	margin-right: 10%;
	margin-top: 10px;
	margin-bottom: 10px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	gap: 50px; /* 设置图片间距 */
	/* background: #ffffff; */
	border-radius: 10px;
	padding-top: 20px;
	padding-bottom: 20px;
}
.f_partners_body_img {
	background: white;
	width: 10%; /* 宽度自适应 */
	height: 60px;
	object-fit: contain;
	padding: 10px;
	border-radius: 6px;
}
@media screen and (max-width: 900px) {
	.f_partners_body_img {
		width: 30%; /* 宽度自适应 */
		height: 40px !important;
	}
	.f_item_header_3_1_1 {
		font-size: 16px;
	}
}
</style>
<!-- f_25 -->
<style scoped>
.f_25 {
	/* min-height: calc(100vh - 80px); */
}
.f_25_1 {
	margin-top: 5%;
	height: 70%;
	margin-left: 10%;
	margin-right: 10%;
}

.f_25_1 img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}
</style>

<!-- f_year_hedaer -->
<style scoped>
.f_year_hedaer {
	margin-top: 8vh;
	padding-top: 10px;
	padding-bottom: 10px;
	margin-left: 10%;
	margin-right: 10%;
}

.f_year_hedaer1 {
	margin-left: 20%;
}
@media screen and (max-width: 900px) {
	.f_partners {
		min-height: auto;
	}
	.f_year_hedaer1 {
		margin-left: 10%;
	}
	.f_25_1 {
		margin-top: 5%;
		height: auto;
		margin-left: 10%;
		margin-right: 10%;
	}
}
</style>

<!-- f_23 -->
<style scoped>
.f_23_1 {
	margin-top: 20px;
	display: inline-flex;
	text-align: center;
	z-index: 2;
	position: relative;
	margin-left: 30px;
}
.f_23_1_1 {
	margin-left: 20px;
}
.f_23_1_item {
	display: flex;
	align-items: center;
}

.f_23_1_item_app {
	display: flex;
}

.f_23_1 img {
	margin-right: 10px;
	width: 20px !important;
	height: 20px !important;
}

.f_23_1 span {
	font-size: 20px;
	font-weight: 400;
	line-height: 20px;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
}
.f_23_2 {
	margin-top: 30px !important;
}
.f_23_5_list {
	width: 80%;
	margin-top: clamp(30px, calc(100vw * 60 / 1280), 80px);
	display: flex;
	padding-left: 10%;
	padding-right: 10%;
	display: flex;
	flex-wrap: wrap; /* 允许子元素换行 */
	gap: 50px; /* 子元素之间的间距 */
	justify-content: space-between;
}

.f_23_5_item {
	width: 30%;
}

.f_23_5_item img {
	width: 100%;
	height: auto;
	object-fit: contain;
}
@media screen and (max-width: 900px) {
	.f_23_1 {
		margin-left: 10px;
	}
	.f_23_1_item_app {
		display: none;
	}

	.f_23_1 img {
		margin-right: 10px;
		width: 14px !important;
		height: 14px !important;
	}

	.f_23_1 span {
		font-size: 14px;
	}

	.f_23_5_list {
		width: 90%;
		margin-top: 60px;
		display: flex;
		padding-left: 5%;
		padding-right: 5%;
		display: flex;
		flex-wrap: wrap; /* 允许子元素换行 */
		gap: 30px; /* 子元素之间的间距 */
		justify-content: space-between;
	}

	.f_23_5_item {
		width: 45%;
	}
	.f_23_5_item img {
		width: 100%;
		height: auto;
	}
}
</style>

<style scoped>
.f_add {
	text-align: start;
	padding-top: 20px;
	padding-bottom: 100px;
	background-image: url("https://res.metaera.hk/resources/assets/images/forum/64.png");
}

.f_add_content {
	display: flex;
	margin-top: 60px;
}

.f_add_content_left {
	margin-top: 0px;
	margin-left: 10%;
	margin-right: 0%;
	justify-content: space-between;
	display: inline-grid;
}

.f_add_content_left_span_1 {
	font-size: 20px;
	font-weight: bold;
	line-height: normal;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}

.f_add_content_left_span_2 {
	font-size: 20px;
	font-weight: 400;
	line-height: normal;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}
.f_add_content_right {
	margin-left: 5%;
	width: 60%;
}

.f_add_content_right img {
	border-radius: 4px;
	width: 60%;
	height: auto;
}

@media screen and (max-width: 900px) {
	.f_add {
		padding-top: 0px;
		padding-bottom: 40px;
	}
	.f_add_content {
		display: block;
		margin-top: 20px;
	}
	.f_add_content_left {
		margin-left: 10%;
		margin-right: 10%;
		justify-content: space-between;
		display: inline-grid;
	}
	.f_add_content_right {
		margin-top: 30px;
		margin-left: 10%;
		width: 100%;
	}
	.f_add_content_right img {
		width: 80%;
		height: auto;
	}
}
</style>

<style scoped>
.f_231,
.f_232,
.f_233 {
	text-align: start;
	/* min-height: calc(100vh - 80px); */
}

.f_232_prople_1 {
	margin-top: 80px;
	padding-top: 60px;
	background-image: url("https://res.metaera.hk/resources/assets/images/forum/64.png");
}

.f_232_prople_1_text {
	margin-top: 20px;
	margin-left: 10%;
	margin-right: 10%;
	font-size: 24px;
	font-weight: normal;
	line-height: 40px;
	text-align: center;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
	align-content: center;
	text-align: center;
}

.f_232_prople_1_email {
	border-radius: 6px;
	opacity: 1;
	background: #6429ff;
	width: 118px;
	height: 44px;
	font-size: 20px;
	font-weight: normal;
	line-height: 40px;
	text-align: center;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
	align-content: center;
	text-align: center;
	margin-left: calc(50vw - 59px);
	margin-top: 60px;
	margin-bottom: 60px;
}

.f_232_prople {
	text-align: start;
	padding-bottom: 40px;
	/* background: #6429ff; */
}

.f_23_5 {
	margin-bottom: 80px;
}

.f_231_1 {
	margin-top: 40px;
}
.f_231_2,
.f_232_2 {
	margin-top: 10px;
	display: block;
	text-align: center !important;
}
.f_232_2 {
	margin-top: 10px;
	margin-bottom: 20px;
}

.f_232_5 {
	margin-left: 20%;
	height: auto;
	width: 60%;
	height: auto;
	position: relative;
	display: block;
	margin-bottom: 0px;
	margin-top: 5%;
}

.f_232_5 video {
	height: 100%;
	width: 100%;
}

.video-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
	border-radius: 10px;
}

.video-overlay-bg {
	width: 100%;
	height: 100%;
	object-fit: cover;
	cursor: pointer;
	border-radius: 10px;
}
.bottom-left-container {
	position: absolute;
	bottom: 25px; /* 你可以根据需要调整位置 */
	left: 0px; /* 你可以根据需要调整位置 */
	border-radius: 0px 10px 10px 0px;
	background: rgba(0, 0, 0, 0.6);
}
.info-box {
	display: flex;
	align-items: center;
}
.info-text {
	margin-left: 15px;
	margin-right: 15px;
	margin-top: 15px;
	margin-bottom: 15px;
	font-family: DINPro-Bold;
	font-size: 16px;
	font-weight: bold;
	line-height: 1.5;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	color: #ffffff;
}
.info-icon {
	margin-right: 15px;
	width: auto;
	height: 20px;
}
.video-overlay-play {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 70px;
	height: auto;
	transform: translate(-50%, -50%);
}
.video-overlay-play_top {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100px;
	height: auto;
	transform: translate(-50%, -50%);
}

.f_231_5 {
	margin-bottom: 40px;
}
.f_231_5_list {
	width: 80%;
	margin-top: 3%;
	display: flex;
	padding-left: 10%;
	padding-right: 10%;
	justify-content: space-between;
	display: flex;
	flex-wrap: wrap; /* 允许子元素换行 */
	gap: 50px; /* 子元素之间的间距 */
	margin-bottom: 0px;
}

.f_231_5_item {
	width: 20%;
}

.f_231_5_item_1 {
	width: 20%;
	text-align: center;
}

/* .f_231_5_item_1 .f_231_5_item_img img {
	height: auto;
	width: 50% !important;
	border-radius: 6px;
} */

.f_231_5_item_img img {
	object-fit: contain;
	aspect-ratio: 1 / 1; /* 宽高比设置为1:1 */
	width: 80%;
	border-radius: 6px;
}
.f_231_5_item_name {
	margin-top: 20px;
	align-content: center;
	text-align: center;
	align-items: center;
}
.f_231_5_item_name img {
	width: 10px;
	height: auto;
	object-fit: contain;
	margin-right: 6px;
}

.f_231_5_item_name span {
	font-size: clamp(12px, calc(100vw * 15 / 1280), 20px);
	font-weight: bold;
	line-height: 20px;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
}

.f_231_5_item_content {
	margin-top: 10px;
	font-size: clamp(8px, calc(100vw * 10 / 1280), 12px);
	font-weight: 400;
	line-height: 20px;
	/* text-align: start; */
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
}
@media screen and (max-width: 900px) {
	.f_232_prople {
		margin-bottom: 40px;
	}
	.f_232_prople_1 {
		margin-top: 50px;
	}
	.f_231_5_list {
		width: 80%;
		margin-top: 15px;
		display: flex;
		padding-left: 10%;
		padding-right: 10%;
		justify-content: space-between;
		display: flex;
		flex-wrap: wrap; /* 允许子元素换行 */
		gap: 10px; /* 子元素之间的间距 */
		margin-bottom: 60px;
	}

	.f_231_5_item {
		width: 40%;
	}

	.f_231_5_item_img img {
		height: 15vh;
		width: auto;
	}

	.f_231_5_item_name {
		margin-top: 10px;
	}

	.f_231_5_item_name img {
		width: 10px;
	}

	.f_232_2 {
		margin-bottom: 40px;
	}

	.f_231_5_item_name span {
		font-size: clamp(12px, calc(100vw * 15 / 1280), 20px);
		font-weight: bold;
		line-height: 20px;
		align-items: center;
		text-transform: uppercase;
		letter-spacing: 0em;
		font-variation-settings: "opsz" auto;
		color: #ffffff;
	}

	.f_231_5_item_content {
		margin-top: 10px;
		font-size: clamp(8px, calc(100vw * 10 / 1280), 12px);
		font-weight: 400;
		line-height: 20px;
		text-transform: uppercase;
		letter-spacing: 0em;
		font-variation-settings: "opsz" auto;
		color: #ffffff;
	}

	.f_232_5 {
		margin-left: 10%;
		height: auto;
		width: 80%;
		position: relative;
		display: inline-block;
		margin-bottom: 60px;
	}

	.f_232_5 video {
		width: 100%;
		height: auto;
	}

	.video-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		cursor: pointer;
		border-radius: 10px;
	}

	.video-overlay-bg {
		width: 100%;
		height: 100%;
		object-fit: cover;
		cursor: pointer;
		border-radius: 10px;
	}
	.bottom-left-container {
		position: absolute;
		bottom: 25px; /* 你可以根据需要调整位置 */
		left: 0px; /* 你可以根据需要调整位置 */
		border-radius: 0px 10px 10px 0px;
		background: rgba(0, 0, 0, 0.6);
	}
	.info-box {
		display: flex;
		align-items: center;
	}
	.info-text {
		margin-left: 15px;
		margin-right: 15px;
		margin-top: 15px;
		margin-bottom: 15px;
		font-family: DINPro-Bold;
		font-size: 16px;
		font-weight: bold;
		line-height: 1.5;
		text-align: center;
		text-transform: uppercase;
		letter-spacing: 0em;

		font-variation-settings: "opsz" auto;
		font-feature-settings: "kern" on;
		color: #ffffff;
	}
	.info-icon {
		margin-right: 15px;
		width: auto;
		height: 20px;
	}
	.video-overlay-play,
	.video-overlay-play_top {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 60px;
		height: auto;
		transform: translate(-50%, -50%);
	}

	.bottom-left-container {
		position: absolute;
		bottom: 15px; /* 你可以根据需要调整位置 */
		left: 0px; /* 你可以根据需要调整位置 */
		border-radius: 0px 10px 10px 0px;
		background: rgba(0, 0, 0, 0.6);
	}
	.info-box {
		display: flex;
		align-items: center;
	}
	.info-text {
		margin-left: 10px;
		margin-right: 6px;
		margin-top: 10px;
		margin-bottom: 10px;
		font-family: DINPro-Bold;
		font-size: 12px;
		font-weight: bold;
		line-height: 1.5;
		text-align: center;
		text-transform: uppercase;
		letter-spacing: 0em;

		font-variation-settings: "opsz" auto;
		font-feature-settings: "kern" on;
		color: #ffffff;
	}
	.info-icon {
		margin-right: 10px;
		width: auto;
		height: 14px;
	}

	.f_232_prople_1_text {
		margin-top: 20px;
		font-size: 16px;
		line-height: 2;
	}
}
</style>

<!-- f_meta -->
<style scoped>
.f_meta {
	text-align: start;
}
.f_meta_3 {
	font-size: 20px !important;
}
.f_meta_4 {
	margin-left: 10%;
	margin-right: 10%;
	margin-top: 10vh;
}

.f_meta_4 span {
	font-size: 26px;
	font-weight: bold;
	line-height: 1.5;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
	background: #6429ff;
	padding: 10px;
}

.f_meta_5 {
	width: 80%;
	margin-left: 10%;
	margin-top: 50px;
	display: flex;
	justify-content: space-between;
	margin-bottom: 0px;
}
.f_meta_5_list_center {
	width: 200px;
}

.f_meta_5_item {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
}
.f_meta_5_item img {
	width: 8px !important;
	height: 8px !important;
}

.f_meta_5_item span {
	margin-left: 6px;
	font-size: 24px;
	font-weight: 600;
	line-height: 1.5;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
}
@media screen and (max-width: 900px) {
	.f_meta_3 {
		font-size: 16px !important;
		width: 70%;
		line-height: 2;
		margin-left: 10px;
	}

	.f_meta_4 {
		margin-left: 10%;
		margin-right: 10%;
		margin-top: 10vh;
	}

	.f_meta_4 span {
		font-size: 16px;
		font-weight: bold;
		line-height: 1.5;
		text-transform: uppercase;
		letter-spacing: 0em;
		font-variation-settings: "opsz" auto;
		color: #ffffff;
		background: #6429ff;
		padding: 10px;
	}

	.f_meta_5 {
		width: 80%;
		margin-left: 10%;
		margin-top: 30px;
		display: flex;
		justify-content: space-around;
		margin-bottom: 60px;
	}

	.f_meta_5_list_center {
		width: 10%;
	}

	.f_meta_5_item {
		display: flex;
		align-items: center;
		margin-bottom: 20px;
	}
	.f_meta_5_item img {
		width: 8px !important;
		height: 8px !important;
	}

	.f_meta_5_item span {
		margin-left: 6px;
		font-size: 12px;
		font-weight: 600;
		line-height: 18px;
		display: flex;
		align-items: center;
		text-transform: uppercase;
		letter-spacing: 0em;
		font-variation-settings: "opsz" auto;
		color: #ffffff;
	}
}
</style>

<!-- f_contact -->
<style scoped>
.f_contact {
	display: none;
}
.f_contact_2 {
	margin-left: 10%;
	margin-right: 10%;
	margin-top: 8vh;
}
.f_contact_1 {
	margin-left: 10%;
	margin-right: 10%;
	margin-top: 5vh;
	margin-bottom: 100px;
}

.f_contact_1_item img {
	background: white;
	padding: 4px;
	border-radius: 4px;
	object-fit: contain;
}

.f_contact_1 span {
	font-size: 20px;
	font-weight: 400;
	line-height: 30px;
	align-items: center;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
	text-transform: lowercase;
}

.f_contact_3 {
	transform: rotate(180deg); /* 旋转 180 度 */
}
@media screen and (max-width: 900px) {
	.f_contact_2 {
		margin-top: 5vh;
	}
	.f_contact_1 {
		margin-top: 5vh;
		margin-bottom: 10vh;
		height: 100px;
		display: inline-block;
		text-align: center;
		align-items: center;
		align-content: center;
	}
}
</style>
<style scoped>
.f_back_content {
	margin-top: 30px;
	display: flex;
	align-content: center;
	align-items: center;
	text-align: center;
}

.f_back_content img {
	width: 80%;
	height: auto;
}
</style>

<style scoped>
.f_pic {
	padding-bottom: 40px;
	background: #6429ff;
}

.f_pic_content_left {
	margin-left: 10%;
	display: inline-grid;
	text-align: start;
	justify-content: space-around;
	min-height: 200px;
}

.f_pic_content_left_line {
	background: #8bffae;
	width: 30px;
	height: 6px;
}

.f_pic_content_left_pic1 img {
	background: white;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 10px;
	padding-bottom: 10px;
	align-items: center;
	height: 20px;
	width: auto;
	border-radius: 10px;
}
.f_pic_content_left_pic2 img {
	height: 60px;
	width: auto;
}
.f_pic_content_left_pic3 img {
	height: 30px;
	width: auto;
}

.f_pic_content_right {
	margin-left: 5%;
	width: 60%;
}

.f_pic_content_right img {
	border-radius: 4px;
	width: 60%;
	height: auto;
}
@media screen and (max-width: 900px) {
	.f_pic_content_left {
		margin-left: 10%;
		margin-right: 10%;
		min-height: 200px;
	}

	.f_pic_content_left_pic1 img {
		background: white;
		padding-left: 20px;
		padding-right: 20px;
		padding-top: 10px;
		padding-bottom: 10px;
		align-items: center;
		height: 20px;
		width: auto;
		border-radius: 10px;
	}
	.f_pic_content_left_pic2 img {
		height: auto;
		width: 90%;
	}
	.f_pic_content_left_pic3 img {
		height: auto;
		width: 60%;
	}
	.f_pic_content_right {
		margin-left: 10%;
		width: 80%;
	}

	.f_pic_content_right img {
		border-radius: 4px;
		width: 100%;
		height: auto;
	}
}
</style>
